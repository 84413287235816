<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac">
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">系列名：</span>
						<el-input class="w-250" v-model="retrieveForm.seriesName" clearable></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">类型：</span>
						<el-select class="w-250" v-model="retrieveForm.seriesType" clearable placeholder="请选择">
							<el-option v-for="item in seriesTypes" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">是否上架：</span>
						<el-select class="w-250" v-model="retrieveForm.shelves" clearable placeholder="请选择">
							<el-option v-for="item in shelves" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div>
						<el-button type="primary" @click="submitSearch">查询</el-button>
					</div>
					<div class="ml-30">
						<el-button type="primary" @click="transferbtn">传递多个</el-button>
					</div>
				</div>
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
				@row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }"
				@selection-change="handleSelectionChange">
				<template v-slot:img="scope">
					<img :src="scope.row.coverImg" alt="" class="smallImg" v-if="scope.row.coverImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 抽盒详情 -->
		<draws-details ref="draws-details" @refresh="getTableData"></draws-details>
		<!-- 修改记录 -->
		<el-dialog title="修改记录" :visible.sync="dialogUseVisible">
			<div class="dialog-content">
				<EleTable ref="recordRef" slot="record" :tableData="recordData" :columns="recordColumns" height="100%"
					v-loading="recLoading">
				</EleTable>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogUseVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogUseVisible = false">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 传递数据 -->
		<el-dialog title="传递数据" :visible.sync="transferVisible">
			<div class="dialog-content">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
					<el-form-item label="选择平台" prop="platformUrl" v-if="'platformUrl' in ruleForm"
						:rules="{required: true, message: '请选择平台', trigger: 'change'}">
						<el-select v-model="ruleForm.platformUrl" placeholder="请选择">
							<el-option v-for="item in platform" :key="item.value" :label="item.key" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="transferVisible = false">取 消</el-button>
				<el-button type="primary" @click="certain">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'draws',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"CountDown": (resolve) => require(["@/components/CountDown"], resolve),
			"draws-details": (resolve) => require(["@/views/modules/goods/drawsDetails"], resolve),
		},
		computed: {
			...mapState('menu', {
				'seriesTypes': state => state.dictList ? state.dictList.series_type : [], //系列分类
				'shelves': state => state.dictList ? state.dictList.shelves : [], //是否上下架
				'boxTypes': state => state.dictList ? state.dictList.box_type : [],
			})
		},
		data() {
			return {
				retrieveForm: {
					seriesName: '',
					seriesType: '',
					seriesCode: '',
					shelves: ''
				},
				tabLoading: false,
				recLoading: false,
				columns: [{
						type: 'selection',
						align: 'center',
						width: "30",
						fixed: 'left'
					},
					{
						type: 'index',
						label: '序号',
						width: '60',
						align: 'center',
						fixed: 'left'
					},
					{
						columnType: 'custom',
						label: '封面图',
						prop: 'img',
						align: 'left',
						width: '80',
						'show-overflow-tooltip': true
					},
					// { label: '系列编码', prop: 'seriesCode', align: 'left', 'show-overflow-tooltip': true },
					{
						label: '系列名称',
						prop: 'seriesName',
						width: '300',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						columnType: 'fun',
						label: '系列分类',
						prop: 'seriesType',
						align: 'left',
						'show-overflow-tooltip': true,
						formatData: (e) => {
							let ele = this.seriesTypes.find(ele => ele.value == e)
							return ele ? ele.key : e
						}
					},
					{
						columnType: 'fun',
						label: '是否上架',
						prop: 'shelves',
						align: 'left',
						'show-overflow-tooltip': true,
						formatData: (e) => {
							let ele = this.shelves.find(ele => ele.value == e)
							return ele ? ele.key : 'eee'
						}
					},
					{
						label: '价格',
						prop: 'soldPrice',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '可兑换的积分',
						prop: 'integral',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '创建时间',
						prop: 'createDate',
						align: 'left',
						width: '200',
						'show-overflow-tooltip': true
					},
					{
						label: '发货日期',
						prop: 'deliverDate',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						width: '200',
						buttons: [{
								type: 'text',
								text: '记录',
								fn: this.recordList
							},
							{
								type: 'text',
								text: '传递',
								fn: this.transferList
							},
							{
								type: 'text',
								text: '编辑',
								fn: this.editList
							},
							{
								type: 'text',
								text: '删除',
								fn: this.deleteList
							},
						],
					},
				],
				tableData: [],
				//弹框
				dialogUseVisible: false,
				recordData: [], //修改记录
				transferVisible: false,
				ruleForm: {
					platformUrl: ''
				},
				transferData: '',
				selectData: '',
				ids: '', //选择的系列id
				platform: [{
					// value: 'http://192.168.3.122:8083/manghe/',
					value: 'https://manghe.hzhxyqchy.com/manghe/',
					key: '乐芒抽盒机'
				}, {
					value: 'https://sauce.hzhxyqchy.com/ouqi/',
					key: '欧气酱潮玩'
				}, {
					value: 'https://bobo.hzhxyqchy.com/bob/',
					key: '啵啵抽盒机'
				}, {
					value: 'https://gull.hzkuqu.com/gull/',
					key: '一只鸥抽盒机'
				}, {
					value: 'https://mart.hzhxyqchy.com/mart/',
					key: '趣丸抽盒机'
				},{
					value: 'https://donut.hzhxyqchy.com/donut/',
					key: '甜甜圈抽盒机'
				},{
					value: 'https://kall.hzhxyqchy.com/kall/',
					key: '咕噜噜抽盒机'
				}],
				recordColumns: [{
						type: 'index',
						label: '序号',
						width: '60',
						align: 'center',
						index: this.indexMethod
					},
					{
						label: '修改日期',
						prop: 'createDate',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '修改用户',
						prop: 'username',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '系列名称',
						prop: 'seriesName',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '发货日期',
						prop: 'deliverDate',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '价格',
						prop: 'soldPrice',
						align: 'left',
						'show-overflow-tooltip': true
					},
				],
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.getTableData()
			},
			//新增列表
			addList() {
				this.$refs['draws-details'].init('', this.tableData)
			},
			//导出
			exportExcel() {},
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				this.$http.get('/boxSeries/findBoxSeries', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						...this.retrieveForm
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				}).catch(err => {
					this.tabLoading = false
				})
			},
			//删除
			deleteList(data) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/boxSeries/removeBoxSeriesById', {
						id: data.id
					}).then(({
						data: result
					}) => {
						this.getTableData()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//编辑
			editList(row) {
				this.$refs['draws-details'].init(row.id)
			},
			//选择传递数据
			handleSelectionChange(val) {
				this.selectData = val
			},
			// 传递单个
			transferList(row) {
				this.transferData = row
				this.transferVisible = true
				this.recLoading = true
			},
			//传递多个
			transferbtn() {
				debugger
				let selectData = this.selectData
				if (selectData.length > 0) {
					this.transferVisible = true
					this.recLoading = true
					let idList = selectData.map(ele => {
						return ele.id
					})
					let ids = idList.join(',')
					this.ids = ids
					// this.certain(ids)
				} else {
					this.$message({
						showClose: true,
						message: '请勾选传递的商品',
						type: 'error'
					});
				}
			},
			//单个传递
			certain() {
				let transferParm = {
					copyUrl: this.ruleForm.platformUrl,
					seriesId: this.ids ? this.ids : this.transferData.id
				}
				// let transfer = JSON.stringify(transferParm)
				this.$http.post('/boxSeries/copySeriesById', transferParm).then(res => {
					this.$message({
						message: '传递成功',
						type: 'success'
					})
					this.ruleForm.platformUrl = ''
					this.transferVisible = false
					this.butLoading = false
					this.getTableData()
					// this.handleClose()
				}).catch(err => {
					this.butLoading = false
				})
			},
			//记录  3抽盒   5一番赏
			recordList(row) {
				this.dialogUseVisible = true
				this.recLoading = true
				this.$http.get('/seriesEdit/findSeriesEdit', {
					params: {
						boxType: 3,
						seriesId: row.id,
					}
				}).then(({
					data: result
				}) => {
					this.recordData = result.data
					this.recLoading = false
				}).catch((err) => {
					this.recLoading = false
				})
			},
			//跳转详情
			headerRowClick(row) {
				console.log(row)
			},
		},
	}
</script>

<style lang="less" scoped>
	.dialog-content {
		max-height: 250px;
	}
</style>